<template>
  <div id="thanks" class="  full-width fx-main pd-0">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div class="container" v-if="order">
      <div
        class="payment-success-block fx-main default-border wd-100 success-bg txt-align-center mg-2"
      >
        <div class="wd-100">
          <h1>{{ isAwaiting ? 'Seu pedido está sendo processado.' : 'Tudo pronto!' }}</h1>
          <h2 v-if="post_order_subtext">{{ post_order_subtext }}</h2>
        </div>
        <div class="wd-100">
          <i class="far fa-check-circle mg-075-em"></i>
        </div>
        <div class="wd-100">
          <p v-if="order.shipping_method">Aguarde nosso contato</p>
          <p v-if="!isAwaiting"> Para verificar seu pedido, <router-link :to="{ 'name': 'order', 'params': { order_id: order.id } }"  class='alterlink'> clique aqui!</router-link></p>
          <p v-else> 
            Pode ficar despreocupado, seu pedido já está no nosso sistema... Vamos encaminhar um e-mail quando o Status dele for alterado.
          </p>
          <p> Obrigado. </p>
        </div>
      </div>
      <div class="default-border full-width" v-if="!isAwaiting">
        <div class="container pd-1-em">
          <h2>Detalhes do Pedido</h2>
          <h4 class="mg-2">Frete: {{ order.shipping_amount | toCurrency }}</h4>
          <h4 class="mg-2">Valor dos Itens: {{ order.items_amount | toCurrency }}</h4>
          <h4 class="mg-2">Valor Total: {{ order.full_amount | toCurrency }}</h4>
          <div
            v-for="orderRow in order.items"
            :key="orderRow.product.id + String(orderRow.variation ? orderRow.variation.id : '')"
            class="fx-main manage-product-item default-border fx-a-i-cent mg-1-bot pd-1-em"
          >

            <div class="manage-product-img">
              <img :src="buildImgUrl(orderRow)" />
            </div>
            <div class="manage-product-quantity">
              <span class="product-quantity">{{ orderRow.quantity }}</span>
            </div>
            <div class="manage-product-unit-price">
              <span
                class="product-price"
              >{{ orderRow.unit_price | toCurrency }}</span>
            </div>
            <div class="manage-product-name">
              <span class="product-name">
                {{ orderRow.product.name }}
                <template v-if="orderRow.variation">&nbsp;- {{ orderRow.variation.name }}</template>
              </span>
            </div>
            <div class="manage-product-price">
              <span
                class="product-price"
              >{{ orderRow.full_price | toCurrency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

  .manage-product-img img {
      width: 8em;
      padding: 1em;
  }
  
</style>
<script>
import Order from "@/services/order.js";

import Loading from "vue-loading-overlay";

import { buildImgUrl } from "@/services/item";

export default {
  name: "ThankYou",
  template: "#thanks",
  components: {
    Loading
  },
  data: function() {
    return {
      order: {},
      isLoading: false,
      post_order_subtext: process.env.VUE_APP_POST_ORDER_SUBTEXT || "",
    };
  },
  props: {
    order_id: String,
    order_object: Object
  },
  computed: {
    order_status: function() {
      if (!this.order || !this.order.status) {
        return null;
      }
      return this.order.status;
    },
    isAwaiting() {
      return this.order_id === 'awaiting-confirmation'
    }
  },
  mounted() {
    if (this.order_object) {
      this.order = this.order_object;
      this.order_id = this.order.id;
    } else {
      this.isLoading = true;
      Order.get(this.order_id)
        .then(order => {
          this.order = order;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    buildImgUrl: function(item) {
      return buildImgUrl(item);
    },
  }
};
</script>